import React from "react";
import { Avatar, Chip, Stack } from "@mui/material";
import { green, red, orange } from "@mui/material/colors";
import { ArrowUpward, ArrowDownward, PriorityHigh } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
const TeamDisplayPlayer = ({ player }) => {
    const teamCodeLookup = {1:3, 2:7, 3:91, 4:94, 5:36, 6:8, 7:31, 8:11, 9:54, 10:40, 11:13, 12:14, 13:43, 14:1, 15:43, 16:17, 17:20, 18:6, 19:21, 20:39};
    const injuredStatusLookup =  ["d","u","i","s","n"];
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

  return (
    <Grid xs key={player.id} display="flex" justifyContent="center" alignItems="center">
        <Stack spacing={0.5} alignItems="center">
        <Avatar src={`https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${teamCodeLookup[player.prem_team_id]}${player.position_short === "GKP" ? "_1" : ""}-66.webp`} />
            <Chip color="success" sx={{fontSize:"8pt"}} variant='square' label={player.last_name} size="small"/>
            <Stack direction="row" spacing={0.5}>
                <Avatar sx={{fontSize:"8pt", height:"18px", width:"18px", bgcolor: green[900]}} size="small">{player.week_points}</Avatar>
                {player.transfer === "in" && <Avatar sx={{fontSize:"8pt", height:"18px", width:"18px", bgcolor: green[300]}}
                 size="small"><ArrowUpward sx={{height:"10pt"}} /></Avatar>}
                {player.transfer === "out" && <Avatar sx={{fontSize:"8pt", height:"18px", width:"18px", bgcolor: red[300]}}
                 size="small"><ArrowDownward sx={{height:"10pt"}} /></Avatar>}
                {injuredStatusLookup.indexOf(player.status) > -1 && 
                <Tooltip onClose={handleTooltipClose} open={open} arrow disableFocusListener disableTouchListener title={player.news}>
                <Avatar sx={{fontSize:"8pt", height:"18px", width:"18px", bgcolor: player.status === "d" ? orange[500] : red[900]}} 
                onClick={handleTooltipOpen} size="small"><PriorityHigh sx={{height:"10pt"}} /></Avatar>
                </Tooltip>}
            </Stack>
        </Stack>
    </Grid >
  );
};

export default TeamDisplayPlayer;