import React, { useState, useEffect } from 'react';
import { callExternalApi } from "../services/external-api.service";
import PremPlayersContext from "../contexts/premplayers-context";

// Component that provides the context value and handles state updates
function PremPlayersProvider({ children }) {
  const [premPlayersData, setPlayers] = useState([]);
  const [isLoadingPremPlayersData, setIsLoading] = useState(true);

  useEffect(() => {
    const tlogApiServerUrl = process.env.REACT_APP_TLOG_API_SERVER_URL;
    const fetchData = async () => {
      try {
        const config = {
          url: `${tlogApiServerUrl}/api/prem-players`,
          method: "GET",
          headers: {"content-type": "application/json",},
        };
        
        const { data, error } = await callExternalApi({ config });
        if (data) {
            setPlayers(data);
        } else {
            console.error('Error fetching prem players:', error);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching prem players:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <PremPlayersContext.Provider value={{ premPlayersData, isLoadingPremPlayersData }}>
      {children}
    </PremPlayersContext.Provider>
  );
}

export default PremPlayersProvider;