import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Chip } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import { green, red} from "@mui/material/colors";
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

export const TeamDisplayTable = (props) => {
  const teamCodeLookup = {1:3, 2:7, 3:91, 4:94, 5:36, 6:8, 7:31, 8:11, 9:54, 10:40, 11:13, 12:14, 13:43, 14:1, 15:43, 16:17, 17:20, 18:6, 19:21, 20:39};

  return (
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 340 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell >Player</TableCell>
            <TableCell>Cost</TableCell>
            <TableCell>Week</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.teamData.players.map((row) => (
              <TableRow key={row.last_name}>
              <TableCell ><span>{row.last_name}</span>
              {row.transfer === "in" && <span> <Chip label="in" color="success" size="small" /></span>}
                {row.transfer === "out" && <span> <Chip label="out" color="warning" size="small" /></span>}
                <br />
                <span style={{fontWeight: 'bold'}}>{row.position_short}</span> {row.team_name_short}</TableCell>
              <TableCell >{row.original_cost}</TableCell>
              <TableCell >{row.week_points}</TableCell>
              <TableCell >{row.total_points}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
    </TableContainer>
  );
};
