import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { getSiteVarsResource, setSiteVarsResource } from "../services/sitevars.service";
import { runScraper } from "../services/scraper.service";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { TableContainer, Paper, List, ListItem, ListItemText, Button } from "@mui/material";


export const ScraperPage = () => {
  const [scrapingEnabled, setScrapingEnabled] = useState(false);
  const [lastUpdated, setLastUpdated] = useState("");
  const [scraperRunning, setScraperRunning] = useState(false);
  
  const handleRunScraper = async () => {
    setScraperRunning(true);
    
    await runScraper();

    setScraperRunning(false);
  };

  useEffect(() => {
    let isMounted = true;

    const getSiteVariables = async () => {
      const { data, error } = await getSiteVarsResource();

      if (!isMounted) {
        return;
      }

      if (data) {
        data.forEach((siteVar) => {
            if(siteVar.name === "allow_scrape") {
                setScrapingEnabled(siteVar.value === "true");
            } else if(siteVar.name === "update_time") {
                setLastUpdated(siteVar.value);
            }
        });
      }

      if (error) {
        setScrapingEnabled(false);
        setLastUpdated("");
      }
    };


    getSiteVariables();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <PageLayout>
      <TableContainer component={Paper}>
      {lastUpdated.length === 0 && (
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
        </Stack>
      )}
      {lastUpdated.length > 0 && (
        <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem>
                <ListItemText
                primary="Scraping Enabled"
                secondary={scrapingEnabled ? "Yes" : "No"}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                primary="Last Updated"
                secondary={lastUpdated}
                />
            </ListItem>
            <ListItem secondaryAction={
                    <Button variant="contained" disabled={scraperRunning} onClick={handleRunScraper}>Go</Button>
                }>
                <ListItemText
                primary="Scrape now"
                />
            </ListItem>
        </List>
      )}
      
    </TableContainer>
    </PageLayout>
  );
};
