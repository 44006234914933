import { callExternalApi } from "./external-api.service";

const tlogApiServerUrl = process.env.REACT_APP_TLOG_API_SERVER_URL;

export const getLoansResource = async (clubId, windowId, accessToken) => {
    
  const config = {
    url: `${tlogApiServerUrl}/api/loans/getLoans`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: {clubId:clubId, windowId:windowId}
  };

  const { data, error } = await callExternalApi({ config });

    return {
      data: data || null,
      error,
  };
};

export const setPlayerLoanResource = async (clubId, windowId, startWeek, playerIn, playerOut) => {
  const config = {
    url: `${tlogApiServerUrl}/api/loans/setPlayerLoan`,
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    data: {clubId:clubId, windowId:windowId, startWeek:startWeek, playerIn:playerIn, playerOut:playerOut}
  };
  
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};