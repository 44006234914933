import React, { useContext, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { SiteVarsContext } from "../contexts/sitevars-context";
import { setSiteVarsResource } from "../services/sitevars.service";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TextField, Button, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const UpdateWeekPage = () => {
  const {siteVarsData} = useContext(SiteVarsContext);
  const [currentWeekValue, setCurrentWeekValue] = useState(siteVarsData[5].value);
  const [siteVarsUpdating, setSiteVarsUpdating] = useState(false);
  
  //setCurrentWeekValue(siteVarsData[5].value);
  
  const handleSetUpdateWeek = async () => {
    setSiteVarsUpdating(true);
    
    await setSiteVarsResource([{name: "current_week", value: currentWeekValue}]);

    setSiteVarsUpdating(false);
  };

  const handleUpdateTextField = (event) => {
    const value = event.target.value;
    setCurrentWeekValue(value);
  };

  return (
    <PageLayout>
      <TableContainer component={Paper}>
      {currentWeekValue === 0 && (
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={"100%"} height={30} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
        </Stack>
      )}
      {currentWeekValue > 0 && (
      <Table sx={{ minWidth: 390 }} size="small">
        <TableBody>
          <TableRow>
            <TableCell align="right">Current week:</TableCell>
            <TableCell>{currentWeekValue}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">New week:</TableCell>
            <TableCell>  
              <TextField id="new-week" onChange={handleUpdateTextField} variant="standard" size="small"/>
            </TableCell>
          </TableRow>
        </TableBody>  
      </Table>
      )}
      
    </TableContainer>

    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
    <Button sx={{ marginTop: "10px", marginRight:"10px" }} disabled={siteVarsUpdating} onClick={handleSetUpdateWeek} variant="contained">Update</Button>
</Box>
    </PageLayout>
  );
};
