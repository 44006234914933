import React, { useState, useContext, useEffect } from "react";
import { PageLayout } from "../components/page-layout";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Fade } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import FinancesClubDialogue from "../components/leaderboard-team-dialogue";
import { LeaderboardContext } from "../contexts/leaderboard-context";
import { getFinancesDataResource } from "../services/finances.service";

export const FinancesPage = () => {
  const {leaderboardData} = useContext(LeaderboardContext);
  const [isFinancesDialogueOpen, setFinancesDialogueIsOpen] = useState(false);
  const [selectedTeamData, setSelectedTeamData] = useState({});
  const [financesData, setFinancesData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const getFinancesData = async () => {
      const { data, error } = await getFinancesDataResource();
      if (!isMounted) { return; }
      if (data) { setFinancesData(data); }
    };

    getFinancesData();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <PageLayout>
      <FinancesClubDialogue open={isFinancesDialogueOpen} teamData={selectedTeamData} key={selectedTeamData.club_name} handleCloseTeamDialogue={setFinancesDialogueIsOpen}/>
      <TableContainer component={Paper}>
      {leaderboardData.length === 0 && (
        <Fade in={true} timeout={1000}>
          <Stack spacing={0.5}>
            <Skeleton variant="rounded" width={"100%"} height={30} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
            <Skeleton variant="rounded" width={"100%"} height={45} />
          </Stack>
        </Fade>
      )}
      {financesData.length > 0 && (
      <Table sx={{ minWidth: 390 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: 189 }}>Club</TableCell>
            <TableCell>Pot buy</TableCell>
            <TableCell>Private buy</TableCell>
            <TableCell>Private sell</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaderboardData.map((row) => (
            <TableRow
              key={row.manager_name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              hover
              onClick={() => {setSelectedTeamData(row); setFinancesDialogueIsOpen(true)}}
            >
              <TableCell component="th" scope="row">
                <span>{row.club_name}</span>
                <br />
                {row.manager_name}
              </TableCell>
              <TableCell align="right">{row.week_score}</TableCell>
              <TableCell align="right">{row.window_score}</TableCell>
              <TableCell align="right">{row.total_score}</TableCell>
              <TableCell align="right">{row.total_score}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      )}
    </TableContainer>
    </PageLayout>
  );
};
