import { useAuth0 } from "@auth0/auth0-react";
import {Fragment, useState, useEffect} from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { LeaderboardContext } from "./contexts/leaderboard-context";
import { SiteVarsContext } from "./contexts/sitevars-context";
import { getLeaderboardResource } from "./services/message.service";
import { getSiteVarsResource } from "./services/sitevars.service";
import { AdminPage } from "./pages/admin-page";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { ProtectedPage } from "./pages/protected-page";
import { LeaderboardPage } from "./pages/leaderboard-page";
import { TransfersPage } from "./pages/transfers-page";
import { LoansPage } from "./pages/loans-page";
import { FinancesPage } from "./pages/finances-page";
import { SiteVariablesPage } from "./pages/sitevariables-page";
import { SiteVarsPage } from "./pages/site-vars-page";
import { UpdateWeekPage } from "./pages/update-week-page";
import { ScraperPage } from "./pages/scraper-page";

export const App = () => {
  const { isLoading } = useAuth0();
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [siteVarsData, setSiteVarsData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const getLeaderboard = async () => {
      const { data, error } = await getLeaderboardResource();
      if (!isMounted) {return;}
      if (data) {
        setLeaderboardData(data);}
    };
    const getSiteVars = async () => {
      const { data, error } = await getSiteVarsResource();
      if (!isMounted) {return;}
      if (data) {
        setSiteVarsData(data);}
    };
    getLeaderboard();
    getSiteVars();
    return () => {
      isMounted = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
  
  const darkTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  return (
    <Fragment>
      <LeaderboardContext.Provider value={{leaderboardData, setLeaderboardData}}>
        <SiteVarsContext.Provider value={{siteVarsData, setSiteVarsData}}>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<LeaderboardPage />} />
              <Route
                path="/profile"
                element={<AuthenticationGuard component={ProfilePage} />}
              />
              <Route path="/leaderboard" element={<LeaderboardPage />} />
              <Route path="/transfers" element={<TransfersPage />} />
              <Route path="/loans" element={<LoansPage />} />
              <Route path="/finances" element={<FinancesPage />} />
              <Route
                path="/protected"
                element={<AuthenticationGuard component={ProtectedPage} />}
              />
              <Route
                path="/admin"
                element={<AuthenticationGuard component={AdminPage} />}
              />
              <Route
                path="/admin/site-vars"
                element={<AuthenticationGuard component={SiteVarsPage} />}
              />
              <Route
                path="/admin/update-week"
                element={<AuthenticationGuard component={UpdateWeekPage} />}
              />
              <Route
                path="/admin/sitevariables"
                element={<AuthenticationGuard component={SiteVariablesPage} />}
              />
              <Route
                path="/admin/scraper"
                element={<AuthenticationGuard component={ScraperPage} />}
              />
              <Route path="/callback" element={<CallbackPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </ThemeProvider>
          </SiteVarsContext.Provider>
      </LeaderboardContext.Provider>
    </Fragment>
  );
};
