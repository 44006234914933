import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavBarTab } from "./nav-bar-tab";

export const NavBarTabs = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="nav-bar__tabs">
      <NavBarTab path="public/leaderboard" label="leaderboard" />
      <NavBarTab path="protected/profile" label="Profile" />
      {isAuthenticated && (
        <>
          <NavBarTab path="auth/protected" label="Protected" />
          <NavBarTab path="/admin" label="Admin" />
          <NavBarTab path="admin/sitevariables" label="Site Variables" />
          <NavBarTab path="admin/updateweek" label="Update Week" />
        </>
      )}
    </div>
  );
};
