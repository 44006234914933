import React, { useState, useContext } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import MakeLoanDialogue from "./make-loan-dialogue";
import { LoansContext } from "../../contexts/loans-context";

export const MakeLoan = (props) => {
  const teamCodeLookup = {1:3, 2:7, 3:91, 4:94, 5:36, 6:8, 7:31, 8:11, 9:54, 10:40, 11:13, 12:14, 13:43, 14:1, 15:43, 16:17, 17:20, 18:6, 19:21, 20:39};
  const [isMakeLoanDialogueOpen, setMakeLoanDialogueIsOpen] = useState(false);
  const [selectedPlayerData, setSelectedPlayerData] = useState({});
  const {loansData} = useContext(LoansContext);

  return (
      <TableContainer component={Paper} sx={{paddingBottom: "55px"}}>
      {isMakeLoanDialogueOpen && (
        <MakeLoanDialogue open={isMakeLoanDialogueOpen} playerData={selectedPlayerData} auctionListData={props.auctionListData} key={selectedPlayerData.prem_team_id} handleCloseMakeLoanDialogue={setMakeLoanDialogueIsOpen}/>
      )}
      {props.leaderboardData.length === 0 && (
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={"100%"} height={30} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
        </Stack>
      )}
      {(props.clubPlayersArr.length > 0) && (
      <Table sx={{ minWidth: 350 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ paddingLeft: '6px', paddingRight: '6px' }}></TableCell>
            <TableCell sx={{ paddingLeft: '6px', paddingRight: '6px' }}>Player</TableCell>
            <TableCell sx={{ paddingLeft: '6px', paddingRight: '6px' }}>Cost</TableCell>
            <TableCell sx={{ paddingLeft: '6px', paddingRight: '6px' }}>Points</TableCell>
            <TableCell sx={{ paddingLeft: '6px', paddingRight: '6px' }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.clubPlayersArr.map((row) => (
            row.transfer != "in" && (
              <TableRow key={row.last_name}>
              <TableCell sx={{ paddingLeft: '6px', paddingRight: '6px' }}><Avatar src={`https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${teamCodeLookup[row.prem_team_id]}${row.position_short === "GKP" ? "_1" : ""}-66.webp`} /></TableCell>
              <TableCell sx={{ paddingLeft: '6px', paddingRight: '6px' }}><span>{row.last_name}</span>
                <br />
                <span style={{fontWeight: 'bold'}}>{row.position_short}</span> {row.team_name_short}</TableCell>
              <TableCell sx={{ paddingLeft: '6px', paddingRight: '6px' }}>{row.original_cost}</TableCell>
              <TableCell sx={{ paddingLeft: '6px', paddingRight: '6px' }}>{row.total_points}</TableCell>
              <TableCell sx={{ paddingLeft: '6px', paddingRight: '6px' }}><Button variant="outlined" size="small" disabled={row.transfer == "out" || 
              loansData.clubCount > 3 || 
              loansData.allLoans?.filter((loan) => loan.playerout === row.player_id).length > 0
              }onClick={() => {setSelectedPlayerData(row); setMakeLoanDialogueIsOpen(true)}}>Bench</Button></TableCell>
            </TableRow>
            )
          ))}
        </TableBody>
      </Table>
      )}
      
    </TableContainer>
  );
};
