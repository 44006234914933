import * as React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { LoginButton } from '../buttons/login-button';
import { ProfileButton } from '../buttons/profile-button';
import Drawer from '@mui/material/Drawer';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import TuneIcon from '@mui/icons-material/Tune';
import BackupIcon from '@mui/icons-material/Backup';
import { useNavigate } from "react-router-dom"; 

export const TopNavBar = () => {
  const { isAuthenticated } = useAuth0();
  const { user } = useAuth0();
  const [drawerState, setDrawerState] = React.useState(false);
  const navigate = useNavigate(); 

  const toggleDrawer = (open) => (event) => {
    setDrawerState(open);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={drawerState}
            onClose={toggleDrawer(false)}
          >
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/")}>
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Home" />
                    </ListItemButton>
                  </ListItem>
                </List>
              <Divider />
              {isAuthenticated && (
              <div>
                <List>
                  <ListItem disablePadding>
                      <ListItemButton onClick={() => navigate("/loans")}>
                        <ListItemIcon>
                          <MoveUpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Loans" />
                      </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
              </div>
              )}
              {(isAuthenticated && user['https://tlog-online.co.uk/club_id'] === 11) && (
              <div>
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/admin/site-vars")}>
                      <ListItemIcon>
                          <TuneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Site Vars" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/admin/update-week")}>
                      <ListItemIcon>
                          <TuneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Update Week" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/admin/scraper")}>
                      <ListItemIcon>
                          <BackupIcon />
                      </ListItemIcon>
                      <ListItemText primary="Scraper" />
                    </ListItemButton>
                  </ListItem>
                </List>
                <Divider />
              </div>
              )}
            </Box>
          </Drawer>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            TLOG
          </Typography>
          {!isAuthenticated && (
            <>
              <LoginButton />
            </>
          )}
          {isAuthenticated && (
            <>
              <ProfileButton />
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
