import { callExternalApi } from "./external-api.service";

const tlogApiServerUrl = process.env.REACT_APP_TLOG_API_SERVER_URL;

export const getFinancesDataResource = async (accessToken) => {
  const config = {
    url: `${tlogApiServerUrl}/api/clubs-finance-summary`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
};

const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
};
};