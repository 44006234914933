import React, { useEffect, useState, useContext, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {setPlayerBidResource } from "../../services/transfers.service";
import { SiteVarsContext } from "../../contexts/sitevars-context";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PremPlayersContext from '../../contexts/premplayers-context';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    //padding: theme.spacing(1),
  },
}));

export default function MakeBidDialogue({open, playerId, handleCloseMakeBidDialogue}) {
  const teamCodeLookup = {"Arsenal":3, "Aston Villa":7, "Bournemouth":91, "Brentford":94, "Brighton":36, "Chelsea":8, "Crystal Palace":31, "Everton":11, "Fulham":54, "Ipswitch":40, "Leicester":13, "Liverpool":14, "Man City":43, "Man Utd":1, "Newcastle":43, "Nott'm Forest":17, "Southampton":20, "Spurs":6, "West Ham":21, "Wolves":39};
  const { user } = useAuth0();
  const bidAmountRef = useRef();
  const [bidError, setBidError] = useState(false);
  const {siteVarsData} = useContext(SiteVarsContext);
  const {premPlayersData, isLoadingPremPlayersData} =  useContext(PremPlayersContext);
  const playerData = premPlayersData.find(player => player.player_id === playerId);

  const handleClose = () => {
    handleCloseMakeBidDialogue(false);
  };

  const handleMakeBid = async () => {
    const clubId = user['https://tlog-online.co.uk/club_id'];
    const bidAmount = bidAmountRef.current.value;
    //bid amount needs to be 2.5 or greater
    if(Number(bidAmount) < 2.5) {
      setBidError(true);
      return;
    } else {
      setBidError(false);
    }
    //const bidPlaceResponse = await setPlayerBidResource(clubId, playerData.player_id, bidAmount, playerData.player_name, playerData.position, playerData.team_name);

    /**
     * Check place bid is successful
     * Set the player as loaned out in the UserPendingTransactionsContext
     */
    
    handleClose();
  };


  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 , lineHeight: 0.8}} id="customized-dialog-title">
          Make Bid
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container>
          <Grid item xs={4} sx={{p:1}}>
          <picture>
            <source type="image/webp" srcSet={`https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${teamCodeLookup[playerData.team_name]}${playerData.position_short === "GKP" ? "_1" : ""}-66.webp 66w, https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${teamCodeLookup[playerData.team_name]}${playerData.position_short === "GKP" ? "_1" : ""}-110.webp 110w, https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${teamCodeLookup[playerData.team_name]}${playerData.position_short === "GKP" ? "_1" : ""}-220.webp 220w`} sizes="(min-width: 124px) 68px, (min-width: 61px) 52px, 36px"/>
              <img src="/dist/img/shirts/standard/shirt_4-66.png" srcSet="https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_4-66.png 66w, https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_4-110.png 110w, https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_4-220.png 220w" sizes="(min-width: 1024px) 68px, (min-width: 610px) 52px, 36px" alt="Newcastle" class="Shirt__StyledShirt-sc-k5q8zl-0 ileRrz"/>
          </picture>
          </Grid>
          <Grid item xs={8}>
          <span style={{fontWeight: 'bold'}}>{playerData.first_name} {playerData.last_name}</span><br />
          {playerData.position} - {playerData.team_name}<br />
          Cost: {playerData.original_cost}<br />
          Points: {playerData.total_points}<br />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={8} sx={{p:1}}>
            <TextField id="standard-basic" label="Bid Amount" error={bidError} helperText={bidError ? "2.5 minimum" : ""} size="6" variant="standard" placeholder="2.5" inputRef={bidAmountRef} />
          </Grid>
          <Grid item xs={4} >
            <Button variant="contained" color="primary" onClick={() => handleMakeBid()}>Make Bid</Button>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </div>
  );
}