import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useContext } from 'react';
import { LoansContext } from '../../contexts/loans-context';

export const AllLoans = (props) => {

  const {loansData, isLoadingLoansData} = useContext(LoansContext);
  const getPlayerName = (playerId) => {
    let playerName = "";
    
    props.leaderboardData.forEach((club) => {
        club.players.forEach((player) => {
            if(player.player_id === playerId) {
                playerName = player.last_name;
                return;
            }
        });
    });

    props.auctionListData.forEach((player) => {
        if(player.player_id === playerId) {
            playerName = player.last_name;
            return;
        }
    });
    return playerName;
  }

  const getClubName = (clubId) => {
    let clubName = "";
    props.leaderboardData.forEach((club) => {
        if(club.club_id === clubId) {
            clubName = club.club_name;
        }
    });
    return clubName;
  }

  return (
      <TableContainer component={Paper} sx={{paddingBottom: "55px"}}>
      <Table sx={{ minWidth: 350 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Club</TableCell>
            <TableCell align='center'>Week</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loansData.allLoans?.map((row) => (
              <TableRow key={row.playerin + "-" + row.playerout}>
                <TableCell >{getClubName(row.clubid)}<br />
                In: <span style={{color:"#666"}}>{getPlayerName(row.playerin)} </span>
                Out: <span style={{color:"#666"}} >{getPlayerName(row.playerout)}</span>
                </TableCell >
                <TableCell align='center' >{row.startweek}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>      
    </TableContainer>
  );
};
