import React, { useState, useContext } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import MakeBidDialogue from "./make-bid-dialogue";
import PremPlayersContext from "../../contexts/premplayers-context";
import SettledWindowTransactionsContext from "../../contexts/settled-window-transactions-context";
import UserPendingTransactionsContext from "../../contexts/user-pending-transactions-context";
import { LeaderboardContext } from "../../contexts/leaderboard-context";

export const MakeBid = (props) => {
  const teamCodeLookup = {"Arsenal":3, "Aston Villa":7, "Bournemouth":91, "Brentford":94, "Brighton":36, "Chelsea":8, "Crystal Palace":31, "Everton":11, "Fulham":54, "Ipswitch":40, "Leicester":13, "Liverpool":14, "Man City":43, "Man Utd":1, "Newcastle":43, "Nott'm Forest":17, "Southampton":20, "Spurs":6, "West Ham":21, "Wolves":39};
  const [isMakeBidDialogueOpen, setMakeBidDialogueIsOpen] = useState(false);
  const [selectedPlayerData, setSelectedPlayerData] = useState({});
  const {premPlayersData, isLoadingPremPlayersData} =  useContext(PremPlayersContext);
  const {leaderboardData} = useContext(LeaderboardContext);
  const {settledWindowTransactionsData, isLoadingSettledWindowTransactionsData} = useContext(SettledWindowTransactionsContext);
  const {userPendingTransactionsData, isLoadingUserPendingTransactionsData} = useContext(UserPendingTransactionsContext);

  //Filter out the players which are already owned by a club
  let ownedPlayers = [];
  let filteredOwnedPlayers = [];
  for(let i = 0; i < leaderboardData.length; i++) {
    ownedPlayers = ownedPlayers.concat(leaderboardData[i].players);
  }
  for(let i = 0; i < ownedPlayers.length; i++) {
    if(ownedPlayers[i].transfer != "in") {
      filteredOwnedPlayers.push(ownedPlayers[i].player_id);
    }
  }
  let filteredPremPlayersData = premPlayersData.filter(player => !filteredOwnedPlayers.includes(player.player_id));

  //filter out players which have already been bought in this window
  let purchasedPlayers = [];
  if(settledWindowTransactionsData.Items === undefined) {
    settledWindowTransactionsData.Items = [];
  }
  for(let i = 0; i < settledWindowTransactionsData.Items.length; i++) {
    let purchasedPlayer = settledWindowTransactionsData.Items[i];
    if(purchasedPlayer.status === "win") {
      purchasedPlayers.push(purchasedPlayer.playerid);
    }
  }
  
  filteredPremPlayersData = filteredPremPlayersData.filter(player => !purchasedPlayers.includes(player.player_id));

  //get the userid's of the users pending transactions
  let pendingTransactions = [];
  let windowBidCount = 0;
  if(userPendingTransactionsData.Items === undefined) {
    userPendingTransactionsData.Items = [];
  }
  for(let i = 0; i < userPendingTransactionsData.Items.length; i++) {
    let pendingTransaction = userPendingTransactionsData.Items[i];
    if(pendingTransaction.status === "pending") {
      pendingTransactions.push(pendingTransaction.playerid);
      windowBidCount++;
    } else if(pendingTransaction.status === "win") {
      windowBidCount++;
    }
  }

  return (
      <TableContainer component={Paper}>
      {isMakeBidDialogueOpen && (
        <MakeBidDialogue open={isMakeBidDialogueOpen} playerId={selectedPlayerData.player_id} handleCloseMakeBidDialogue={setMakeBidDialogueIsOpen} />
      )}
      {(isLoadingPremPlayersData || isLoadingSettledWindowTransactionsData || isLoadingUserPendingTransactionsData) && (
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={"100%"} height={30} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
        </Stack>
      )}
      {(!isLoadingPremPlayersData && !isLoadingSettledWindowTransactionsData && !isLoadingUserPendingTransactionsData) && (
      <Table sx={{ minWidth: 390 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell >Player</TableCell>
            <TableCell>Cost</TableCell>
            <TableCell>Points</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredPremPlayersData.map((row) => (
            row.transfer != "in" && (
              <TableRow key={row.player_id}>
              <TableCell ><Avatar src={`https://fantasy.premierleague.com/dist/img/shirts/standard/shirt_${teamCodeLookup[row.team_name]}${row.position_short === "GKP" ? "_1" : ""}-66.webp`} /></TableCell>
              <TableCell ><span>{row.last_name}</span>
                <br />
                <span style={{fontWeight: 'bold'}}>{row.position_short}</span> {row.team_name_short}</TableCell>
              <TableCell >{row.original_cost}</TableCell>
              <TableCell >{row.total_points}</TableCell>
              <TableCell ><Button variant="outlined" size="small" disabled={windowBidCount > 3 || pendingTransactions.includes(row.player_id)}onClick={() => {setSelectedPlayerData(row); setMakeBidDialogueIsOpen(true)}}>Bid</Button></TableCell>
            </TableRow>
            )
          ))}
        </TableBody>
      </Table>
      )}
      
    </TableContainer>
  );
};
