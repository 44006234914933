import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { getSiteVarsResource, setSiteVarsResource } from "../services/sitevars.service";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Switch, Button, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const SiteVarsPage = () => {
  const [siteVarsData, setSiteVarsData] = useState([]);
  const [siteVarsUpdating, setSiteVarsUpdating] = useState(false);
  
  const handleSetSiteVariables = async () => {
    setSiteVarsUpdating(true);
    
    await setSiteVarsResource(siteVarsData);

    setSiteVarsUpdating(false);
  };

  const handleUpdateTextField = (event) => {
    const name = event.target.id.replace("value-", "");
    const value = event.target.value;
    const newSiteVarsData = siteVarsData.map((siteVar) => {
      if(siteVar.name === name) {
        siteVar.value = value;
      }
      return siteVar;
    });
    setSiteVarsData(newSiteVarsData);
  };

  const handleUpdateSwitch = (event) => {
    const name = event.target.id.replace("value-", "");
    const value = event.target.checked ? "true" : "false";
    const newSiteVarsData = siteVarsData.map((siteVar) => {
      if(siteVar.name === name) {
        siteVar.value = value;
      }
      return siteVar;
    });
    setSiteVarsData(newSiteVarsData);
  };

  useEffect(() => {
    let isMounted = true;

    const getSiteVariables = async () => {
      const { data, error } = await getSiteVarsResource();

      if (!isMounted) {
        return;
      }

      if (data) {
        setSiteVarsData(data);
      }

      if (error) {
        setSiteVarsData(null);
      }
    };


    getSiteVariables();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <PageLayout>
      <TableContainer component={Paper}>
      {siteVarsData.length === 0 && (
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={"100%"} height={30} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
          <Skeleton variant="rounded" width={"100%"} height={26} />
        </Stack>
      )}
      {siteVarsData.length > 0 && (
      <Table sx={{ minWidth: 350 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: 189 }}>Name</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {siteVarsData.map((row) => (
            <TableRow key={row.name}>
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">
                {row.value === "true" || row.value === "false" ?
                <Switch id={"value-" + row.name} onChange={handleUpdateSwitch} defaultChecked={row.value === "true"} size="small" />
                :
                <TextField id={"value-" + row.name} onChange={handleUpdateTextField} variant="standard" size="small" defaultValue={row.value}/>
                }
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      )}
      
    </TableContainer>

    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
    <Button sx={{ marginTop: "10px", marginRight:"10px" }} disabled={siteVarsUpdating} onClick={handleSetSiteVariables} variant="contained">Update</Button>
</Box>
    </PageLayout>
  );
};
