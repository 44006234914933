import { callExternalApi } from "./external-api.service";

const tlogApiServerUrl = process.env.REACT_APP_TLOG_API_SERVER_URL;

export const getSiteVarsResource = async (accessToken) => {
  const config = {
    url: `${tlogApiServerUrl}/api/site-vars/getVars`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
};

const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
};
};

export const setSiteVarsResource = async (varsArr) => {

    let postData = {};
    varsArr.forEach((siteVar) => {
        postData[siteVar.name] = siteVar.value;
    });

    const config = {
        url: `${tlogApiServerUrl}/api/site-vars/setVars`,
        method: "POST",
        headers: {
            "content-type": "application/json"
      },
        data: {siteVars:postData}
    };
    
    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
  };