import React, { useState, useEffect } from 'react';
import { callExternalApi } from "../services/external-api.service";
import {SiteVarsContext} from "../contexts/sitevars-context";
import {LoansContext} from "../contexts/loans-context";
import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// Component that provides the context value and handles state updates
function LoansProvider({ children }) {
  const [loansData, setLoans] = useState([]);
  const [isLoadingLoansData, setIsLoading] = useState(true);
  const { isAuthenticated, user } = useAuth0();
  const {siteVarsData} = useContext(SiteVarsContext);
  const windowId = siteVarsData[0].value;

  useEffect(() => {
    const tlogApiServerUrl = process.env.REACT_APP_TLOG_API_SERVER_URL;
    const accessToken = isAuthenticated ? user['https://tlog-online.co.uk/access_token'] : null;
    const clubId = isAuthenticated ? user['https://tlog-online.co.uk/club_id'] : null;
    const fetchData = async () => {
      try {
        const config = {
          url: `${tlogApiServerUrl}/api/loans/getLoans`,
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          data: {clubId:clubId, windowId:windowId}
        };
        
        const { data, error } = await callExternalApi({ config });
        if (data) {
            
            data.allLoans.sort((a, b) => {
                if (b.startweek - a.startweek === 0) {
                  return a.clubid - b.clubid; // Adjust this line based on how you want to sort clubid
                } else {
                  return b.startweek - a.startweek;
                }
              });

            setLoans(data);
        } else {
            console.error('Error fetching loans:', error);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching loans:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const addLoan = (newLoan) => {
    //add the new loan to the loans array on the loansData state object. The object spread operator is used to create a new object with the new loan added to the loans array.
    setLoans({ 
        ...loansData, 
        loans: [newLoan, ...loansData.loans],
        allLoans: [newLoan, ...loansData.allLoans],
        clubCount: loansData.clubCount + 1
      });
    
    debugger;
  };

  return (
    <LoansContext.Provider value={{ loansData, isLoadingLoansData, addLoan }}>
      {children}
    </LoansContext.Provider>
  );
}

export default LoansProvider;