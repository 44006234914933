import React, { useState, useContext, useEffect } from "react";
import { PageLayout } from "../components/page-layout";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Fade } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import GavelIcon from '@mui/icons-material/Gavel';
import { LeaderboardContext } from "../contexts/leaderboard-context";
import { getAuctionListResource } from "../services/auction.service";
import { MakeBid } from "../components/transfers/make-bid";
import PremPlayersProvider from "../providers/premplayers-provider";
import SettledWindowTransactionsProvider from "../providers/settled-window-transactions-provider";
import UserPendingTransactionsProvider from "../providers/user-pending-tansactions-provider";

/*

Page contains 4 tabs:
1. Make Bid - Make a bid on a pot player. Uses premPlayersData and leaderboardData.
2. My Bids - View all bids made by the user. Uses premPlayersData, transactionsData and userPendingTransactionsData.
3. Buy/Sell - Buy and sell players. Uses premPlayersData, userPendingTransactionsData and leaderboardData.
4. Manage Team - Manage the user's team. Uses leaderboardData, premPlayersData and transactionsData.

premPlayersData is used in all tabs so create a context for it.

Data sources:
- * leaderboardData: List of all clubs, their players and their points. This is retrieved from the leaderboard context
- * premPlayersData: List of all players in the Premier League. This is retrieved from /prem-players
- transactionsData: List of all completed transactions in the current window. This is retrieved from /get-bids/current-window-settled
- userPendingTransactionsData: List of all pending transactions for the user. This is retrieved from /get-bids/club with clubId in the request body

*/


export const TransfersPage = () => {
  const [navValue, setNavValue] = React.useState(0);

  return (
    <PremPlayersProvider>
      <SettledWindowTransactionsProvider>
        <UserPendingTransactionsProvider>
          <PageLayout>
            {navValue == 0 && (
                <MakeBid />
            )}
            {navValue == 1 && (
                <MakeBid />
            )}
            {navValue == 2 && (
                <MakeBid />
            )}
            {navValue == 3 && (
                <MakeBid />
            )}
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
              <BottomNavigation
                  showLabels
                  value={navValue}
                  onChange={(event, newValue) => {
                      setNavValue(newValue);
                  }}
                  >
                  <BottomNavigationAction label="Make Bid" icon={<GavelIcon />} />
                  <BottomNavigationAction label={"My Bids (" + (0) + ")"} icon={<PersonIcon />} />
                  <BottomNavigationAction label="Buy/Sell" icon={<MoveUpIcon />} />
                  <BottomNavigationAction label="Manage Team" icon={<GroupIcon />} />
              </BottomNavigation>
            </Paper>
          </PageLayout>
        </UserPendingTransactionsProvider>
      </SettledWindowTransactionsProvider>
    </PremPlayersProvider>
  );
};
