import React from "react";
import Paper from '@mui/material/Paper';

export const PageFooter = () => {

  return (
    <Paper elevation={3} >
      {/* <span size="small">Copyright Tlog est. 2007</span> */}
    </Paper>
  );
};
