import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { callExternalApi } from "../services/external-api.service";
import UserPendingTransactionsContext from "../contexts/user-pending-transactions-context";

// Component that provides the context value and handles state updates
function UserPendingTransactionsProvider({ children }) {
  const [userPendingTransactionsData, setTransactions] = useState([]);
  const [isLoadingUserPendingTransactionsData, setIsLoading] = useState(true);
  const { user } = useAuth0();

  useEffect(() => {
    const tlogApiServerUrl = process.env.REACT_APP_TLOG_API_SERVER_URL;
    const fetchData = async () => {
      try {
        const config = {
          url: `${tlogApiServerUrl}/api/get-bids/club`,
          method: "POST",
          headers: {"content-type": "application/json",},
          data: {clubId:user['https://tlog-online.co.uk/club_id']},
        };
        
        const { data, error } = await callExternalApi({ config });
        if (data) {
            setTransactions(data);
        } else {
            console.error('Error fetching user pending transactions:', error);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user pending transactions:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <UserPendingTransactionsContext.Provider value={{ userPendingTransactionsData, isLoadingUserPendingTransactionsData }}>
      {children}
    </UserPendingTransactionsContext.Provider>
  );
}

export default UserPendingTransactionsProvider;